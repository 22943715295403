<template>
  <el-card>
    <CommonForm
      label-position="right"
      label-width="130px"
      :formDataGroups="formDataGroups"
      :confirm-txt="confirmTxt"
      createDataType="bed"
      :apifunc="apifunc"
      :modifyData="modifyData"
    />
  </el-card>
</template>

<script>
import CommonForm from '@/components/CommonForm/index.vue'
import { updateFormMixin } from '@/mixin/updateFormMixin'
import {
  getBedInfo,
  createBed,
  updateBedInfo,
  getInstitutionList
} from '@/services'
import jsonData  from './jsonData'
export default {
  components: {
    CommonForm
  },
  mixins: [updateFormMixin],
  data () {
    return {
      formDataGroups: jsonData.formDataGroups
    }
  },
  created () {
    this.initData(createBed, updateBedInfo)
    this.initInfo(getBedInfo, 'bed', 'institution')
    this.initFormData()
  },
  methods: {
    async initFormData() {
      try {
        const res = await getInstitutionList()
        if (res.data.institutions && res.data.institutions.length > 0) {
          this.formDataGroups[0].formDataItems[1].options = res.data.institutions
        }
      } catch (error) {
        
      }
    }
  }
}
</script>